var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.is_mounted
    ? _c("div", { attrs: { align: "center" } }, [
        _c("div", { staticClass: "text-h4" }, [_vm._v("Download documenti")]),
        _c("br"),
        _c("strong", [
          _vm._v(
            "Scarica le dichiarazioni Contraente da far firmare e poi manda l'email al Cliente prima di continuare"
          ),
        ]),
        _c("br"),
        _c("br"),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.is_loading,
                expression: "is_loading",
              },
            ],
            staticClass:
              "text-center justify-center q-pa-md q-gutter-sm fixed-center",
            staticStyle: { "z-index": "1000" },
          },
          [_c("q-spinner-hourglass", { attrs: { size: "4em" } })],
          1
        ),
        _c("div", { staticClass: "row justify-center" }, [
          _c("div", { staticClass: "col-12 col-md-7" }, [
            _c(
              "fieldset",
              {
                staticStyle: { "margin-right": "12px", "min-height": "190px" },
              },
              [
                _vm._m(0),
                _c(
                  "div",
                  { attrs: { align: "left" } },
                  [
                    _vm._v(
                      " Clicca sul link sotto per scaricare le dichiarazioni del Cliente "
                    ),
                    _c("strong", [_vm._v("DA FAR FIRMARE")]),
                    _c("br"),
                    _c("br"),
                    _c(
                      "q-list",
                      { attrs: { bordered: "", separator: "" } },
                      [
                        _c(
                          "q-item",
                          {
                            directives: [
                              { name: "ripple", rawName: "v-ripple" },
                            ],
                            attrs: { clickable: "" },
                            on: { click: _vm.ScaricaDichiarazioniContraente },
                          },
                          [
                            _c(
                              "q-item-section",
                              { attrs: { avatar: "" } },
                              [
                                _c("q-avatar", {
                                  attrs: {
                                    color: "blue-grey",
                                    "text-color": "white",
                                    icon: "mdi-download",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("q-item-section", [
                              _c("strong", [
                                _vm._v("Scarica Dichiarazioni Cliente"),
                              ]),
                            ]),
                            _c(
                              "q-item-section",
                              { attrs: { side: "" } },
                              [
                                _c("q-item-label", { attrs: { caption: "" } }, [
                                  _c(
                                    "strong",
                                    { staticStyle: { "font-size": "1.4em" } },
                                    [_vm._v("LEGGERE E FIRMARE")]
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("br"),
              ]
            ),
            _c("br"),
          ]),
          _vm.otp_identificazione_enabled
            ? _c("div", { staticClass: "col-12 col-md-7" }, [
                _c(
                  "fieldset",
                  { staticStyle: { "margin-right": "12px" } },
                  [
                    _vm._m(1),
                    !_vm.info_cellulare.is_certificato && !_vm.otp_identificato
                      ? [
                          _vm.is_cellulare_bloccato
                            ? [
                                _vm._v(
                                  " Il Cellulare risulta bloccato poichè è usato da più Clienti."
                                ),
                                _c("br"),
                                !_vm.isSede
                                  ? _c("div", [
                                      _vm._v(
                                        " Contatta la Sede per verificare la possibilità di sbloccare il numero di cellulare. "
                                      ),
                                    ])
                                  : _vm._e(),
                                _c("br"),
                                _vm.isSede
                                  ? _c(
                                      "div",
                                      [
                                        _c("QQButton", {
                                          attrs: {
                                            label:
                                              "Sblocca il numero di cellulare",
                                            color: "blue-grey",
                                            icon: "mdi-cellphone-key",
                                            size: "md",
                                          },
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.onSbloccaCellulare.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        }),
                                        _c("br"),
                                        _c("br"),
                                        _c("strong", [_vm._v("ATTENZIONE:")]),
                                        _vm._v(
                                          " Il numero di cellulare sarà sbloccato solo per questo preventivo. "
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                          !_vm.is_cellulare_bloccato
                            ? [
                                _vm._m(2),
                                _c("QQButton", {
                                  attrs: {
                                    label:
                                      "Effettua la certificazione della SIM",
                                    color: "blue-grey",
                                    icon: "mdi-email-check-outline",
                                    size: "md",
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.onVisualizzaModaleOtp.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                }),
                                _c(
                                  "q-dialog",
                                  {
                                    attrs: { persistent: "" },
                                    model: {
                                      value: _vm.visualizza_finestra_otp,
                                      callback: function ($$v) {
                                        _vm.visualizza_finestra_otp = $$v
                                      },
                                      expression: "visualizza_finestra_otp",
                                    },
                                  },
                                  [
                                    _c(
                                      "q-card",
                                      { staticStyle: { width: "600px" } },
                                      [
                                        _c("q-card-section", [
                                          _c(
                                            "div",
                                            { staticClass: "text-h6" },
                                            [
                                              _vm._v(
                                                "Certificazione recapito OTP"
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c(
                                          "q-card-section",
                                          { staticClass: "q-pt-none" },
                                          [
                                            _c("QQOtp", {
                                              attrs: {
                                                title: "",
                                                cellulare:
                                                  _vm.cellulare_cliente,
                                                guid_cliente:
                                                  _vm.formPreventivo.IDCliente,
                                                certificazione: "",
                                                enable_change_num: "",
                                              },
                                              model: {
                                                value: _vm.otp_identificato,
                                                callback: function ($$v) {
                                                  _vm.otp_identificato = $$v
                                                },
                                                expression: "otp_identificato",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "q-card-actions",
                                          { attrs: { align: "right" } },
                                          [
                                            _c("q-btn", {
                                              directives: [
                                                {
                                                  name: "close-popup",
                                                  rawName: "v-close-popup",
                                                },
                                              ],
                                              attrs: {
                                                label: "ESCI",
                                                color: "secondary",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                        ]
                      : _vm._e(),
                    _vm.info_cellulare.is_certificato || _vm.otp_identificato
                      ? [
                          _c("h6", [
                            _vm._v(
                              "Il numero di cellulare risulta certificato come valido"
                            ),
                          ]),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
                _c("br"),
              ])
            : _vm._e(),
          _c("div", { staticClass: "col-12 col-md-7" }, [
            _c(
              "fieldset",
              {
                staticStyle: { "margin-right": "12px", "min-height": "240px" },
              },
              [
                _vm._m(3),
                _vm._m(4),
                _c("p", [
                  _vm._v("Email Cliente: "),
                  _c("strong", { staticStyle: { "font-size": "16px" } }, [
                    _vm._v(_vm._s(_vm.getEmailCliente)),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "col-12 col-md-4",
                    attrs: { align: "center" },
                  },
                  [
                    _c("QQButton", {
                      attrs: {
                        label: "Modifica Email",
                        color: "blue-grey",
                        icon: "undo",
                        size: "md",
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.onIndietroClicked.apply(null, arguments)
                        },
                      },
                    }),
                    _c("QQButton", {
                      attrs: {
                        label: "Manda Email al Cliente",
                        color: "blue-grey",
                        icon: "mdi-email-check-outline",
                        size: "md",
                        disabled: _vm.isEmailDisabled,
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.onInviaEmailClicked.apply(null, arguments)
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c("br"),
              ]
            ),
            _c("br"),
          ]),
          _c(
            "div",
            { staticClass: "col-12 col-md-7", attrs: { align: "left" } },
            [
              _c(
                "fieldset",
                {
                  staticStyle: {
                    "margin-right": "12px",
                    "min-height": "190px",
                  },
                },
                [
                  _vm._m(5),
                  _c(
                    "div",
                    { attrs: { align: "left" } },
                    [
                      _vm._v(
                        " Clicca sul link sotto per scaricare i documenti da far leggere al Contraente "
                      ),
                      _c("br"),
                      _c("br"),
                      _c(
                        "q-list",
                        { attrs: { bordered: "", separator: "" } },
                        [
                          _c(
                            "q-item",
                            {
                              directives: [
                                { name: "ripple", rawName: "v-ripple" },
                              ],
                              attrs: { clickable: "" },
                              on: {
                                click: _vm.ScaricaCondizioniPreContrattuali,
                              },
                            },
                            [
                              _c(
                                "q-item-section",
                                { attrs: { avatar: "" } },
                                [
                                  _c("q-avatar", {
                                    attrs: {
                                      color: "blue-grey",
                                      "text-color": "white",
                                      icon: "mdi-download",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("q-item-section", [
                                _c("strong", [
                                  _vm._v(
                                    "Scarica DOC1 - Documentazione Pre-Contrattuale"
                                  ),
                                ]),
                              ]),
                              _c(
                                "q-item-section",
                                { attrs: { side: "" } },
                                [
                                  _c(
                                    "q-item-label",
                                    { attrs: { caption: "" } },
                                    [
                                      _c(
                                        "strong",
                                        {
                                          staticStyle: { "font-size": "1.4em" },
                                        },
                                        [_vm._v("LEGGERE")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "q-item",
                            {
                              directives: [
                                { name: "ripple", rawName: "v-ripple" },
                              ],
                              attrs: { clickable: "" },
                              on: { click: _vm.ScaricaPrivacy },
                            },
                            [
                              _c(
                                "q-item-section",
                                { attrs: { avatar: "" } },
                                [
                                  _c("q-avatar", {
                                    attrs: {
                                      color: "blue-grey",
                                      "text-color": "white",
                                      icon: "mdi-download",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("q-item-section", [
                                _c("strong", [
                                  _vm._v(
                                    "Scarica DOC2 - Informativa sulla Privacy"
                                  ),
                                ]),
                              ]),
                              _c(
                                "q-item-section",
                                { attrs: { side: "" } },
                                [
                                  _c(
                                    "q-item-label",
                                    { attrs: { caption: "" } },
                                    [
                                      _c(
                                        "strong",
                                        {
                                          staticStyle: { "font-size": "1.4em" },
                                        },
                                        [_vm._v("LEGGERE")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "q-item",
                            {
                              directives: [
                                { name: "ripple", rawName: "v-ripple" },
                              ],
                              attrs: { clickable: "" },
                              on: { click: _vm.ScaricaReclami },
                            },
                            [
                              _c(
                                "q-item-section",
                                { attrs: { avatar: "" } },
                                [
                                  _c("q-avatar", {
                                    attrs: {
                                      color: "blue-grey",
                                      "text-color": "white",
                                      icon: "mdi-download",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("q-item-section", [
                                _c("strong", [
                                  _vm._v("Scarica DOC3 - Gestione dei Reclami"),
                                ]),
                              ]),
                              _c(
                                "q-item-section",
                                { attrs: { side: "" } },
                                [
                                  _c(
                                    "q-item-label",
                                    { attrs: { caption: "" } },
                                    [
                                      _c(
                                        "strong",
                                        {
                                          staticStyle: { "font-size": "1.4em" },
                                        },
                                        [_vm._v("LEGGERE")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.haConsulenza
                            ? _c(
                                "q-item",
                                {
                                  directives: [
                                    { name: "ripple", rawName: "v-ripple" },
                                  ],
                                  attrs: { clickable: "" },
                                  on: {
                                    click:
                                      _vm.ScaricaRaccomandazionePersonalizzata,
                                  },
                                },
                                [
                                  _c(
                                    "q-item-section",
                                    { attrs: { avatar: "" } },
                                    [
                                      _c("q-avatar", {
                                        attrs: {
                                          color: "blue-grey",
                                          "text-color": "white",
                                          icon: "mdi-download",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("q-item-section", [
                                    _c("strong", [
                                      _vm._v(
                                        "Scarica DOC4 - Raccomandazione Personalizzata"
                                      ),
                                    ]),
                                  ]),
                                  _c(
                                    "q-item-section",
                                    { attrs: { side: "" } },
                                    [
                                      _c(
                                        "q-item-label",
                                        { attrs: { caption: "" } },
                                        [
                                          _c(
                                            "strong",
                                            {
                                              staticStyle: {
                                                "font-size": "1.4em",
                                              },
                                            },
                                            [_vm._v("LEGGERE")]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "q-item",
                            {
                              directives: [
                                { name: "ripple", rawName: "v-ripple" },
                              ],
                              attrs: { clickable: "" },
                              on: { click: _vm.ScaricaElencoCompagnie },
                            },
                            [
                              _c(
                                "q-item-section",
                                { attrs: { avatar: "" } },
                                [
                                  _c("q-avatar", {
                                    attrs: {
                                      color: "blue-grey",
                                      "text-color": "white",
                                      icon: "mdi-download",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("q-item-section", [
                                _c("strong", [
                                  _vm._v("Scarica DOC5 - Elenco Compagnie"),
                                ]),
                              ]),
                              _c(
                                "q-item-section",
                                { attrs: { side: "" } },
                                [
                                  _c(
                                    "q-item-label",
                                    { attrs: { caption: "" } },
                                    [
                                      _c(
                                        "strong",
                                        {
                                          staticStyle: { "font-size": "1.4em" },
                                        },
                                        [_vm._v("LEGGERE")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.isAbyBroker
                            ? _c(
                                "q-item",
                                {
                                  directives: [
                                    { name: "ripple", rawName: "v-ripple" },
                                  ],
                                  attrs: { clickable: "" },
                                  on: { click: _vm.ScaricaPOGAby },
                                },
                                [
                                  _c(
                                    "q-item-section",
                                    { attrs: { avatar: "" } },
                                    [
                                      _c("q-avatar", {
                                        attrs: {
                                          color: "blue-grey",
                                          "text-color": "white",
                                          icon: "mdi-download",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("q-item-section", [
                                    _c("strong", [
                                      _vm._v(
                                        "Scarica DOC6 - POG di Aby Broker"
                                      ),
                                    ]),
                                  ]),
                                  _c(
                                    "q-item-section",
                                    { attrs: { side: "" } },
                                    [
                                      _c(
                                        "q-item-label",
                                        { attrs: { caption: "" } },
                                        [
                                          _c(
                                            "strong",
                                            {
                                              staticStyle: {
                                                "font-size": "1.4em",
                                              },
                                            },
                                            [_vm._v("LEGGERE")]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "q-item",
                            {
                              directives: [
                                { name: "ripple", rawName: "v-ripple" },
                              ],
                              attrs: { clickable: "" },
                              on: { click: _vm.ScaricaFilieraDistributiva },
                            },
                            [
                              _c(
                                "q-item-section",
                                { attrs: { avatar: "" } },
                                [
                                  _c("q-avatar", {
                                    attrs: {
                                      color: "blue-grey",
                                      "text-color": "white",
                                      icon: "mdi-download",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("q-item-section", [
                                _c("strong", [
                                  _vm._v(
                                    "Scarica Filiera distributiva e Pagamento del premio"
                                  ),
                                ]),
                              ]),
                              _c(
                                "q-item-section",
                                { attrs: { side: "" } },
                                [
                                  _c(
                                    "q-item-label",
                                    { attrs: { caption: "" } },
                                    [
                                      _c(
                                        "strong",
                                        {
                                          staticStyle: { "font-size": "1.4em" },
                                        },
                                        [_vm._v("LEGGERE")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "q-item",
                            {
                              directives: [
                                { name: "ripple", rawName: "v-ripple" },
                              ],
                              attrs: { clickable: "" },
                              on: { click: _vm.ScaricaMercatoRiferimento },
                            },
                            [
                              _c(
                                "q-item-section",
                                { attrs: { avatar: "" } },
                                [
                                  _c("q-avatar", {
                                    attrs: {
                                      color: "blue-grey",
                                      "text-color": "white",
                                      icon: "mdi-download",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("q-item-section", [
                                _c("strong", [
                                  _vm._v("Scarica Mercato di Riferimento"),
                                ]),
                              ]),
                              _c(
                                "q-item-section",
                                { attrs: { side: "" } },
                                [
                                  _c(
                                    "q-item-label",
                                    { attrs: { caption: "" } },
                                    [
                                      _c(
                                        "strong",
                                        {
                                          staticStyle: { "font-size": "1.4em" },
                                        },
                                        [_vm._v("LEGGERE")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "q-item",
                            {
                              directives: [
                                { name: "ripple", rawName: "v-ripple" },
                              ],
                              attrs: { clickable: "" },
                              on: { click: _vm.ScaricaDichiarazioniCoerenza },
                            },
                            [
                              _c(
                                "q-item-section",
                                { attrs: { avatar: "" } },
                                [
                                  _c("q-avatar", {
                                    attrs: {
                                      color: "blue-grey",
                                      "text-color": "white",
                                      icon: "mdi-download",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("q-item-section", [
                                _c("strong", [
                                  _vm._v("Scarica Dichiarazione di Coerenza"),
                                ]),
                              ]),
                              _c(
                                "q-item-section",
                                { attrs: { side: "" } },
                                [
                                  _c(
                                    "q-item-label",
                                    { attrs: { caption: "" } },
                                    [
                                      _c(
                                        "strong",
                                        {
                                          staticStyle: { "font-size": "1.4em" },
                                        },
                                        [_vm._v("LEGGERE")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "q-item",
                            {
                              directives: [
                                { name: "ripple", rawName: "v-ripple" },
                              ],
                              attrs: { clickable: "" },
                              on: { click: _vm.ScaricaElencoPreventivi },
                            },
                            [
                              _c(
                                "q-item-section",
                                { attrs: { avatar: "" } },
                                [
                                  _c("q-avatar", {
                                    attrs: {
                                      color: "blue-grey",
                                      "text-color": "white",
                                      icon: "mdi-download",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("q-item-section", [
                                _c("strong", [
                                  _vm._v("Scarica Elenco Preventivi"),
                                ]),
                              ]),
                              _c(
                                "q-item-section",
                                { attrs: { side: "" } },
                                [
                                  _c(
                                    "q-item-label",
                                    { attrs: { caption: "" } },
                                    [
                                      _c(
                                        "strong",
                                        {
                                          staticStyle: { "font-size": "1.4em" },
                                        },
                                        [_vm._v("LEGGERE")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("br"),
                ]
              ),
              _c("br"),
            ]
          ),
          _c(
            "div",
            { staticClass: "col-12 col-md-7", attrs: { align: "left" } },
            [
              _c(
                "fieldset",
                {
                  staticStyle: {
                    "margin-right": "12px",
                    "min-height": "240px",
                  },
                },
                [
                  _vm._m(6),
                  _c(
                    "q-list",
                    { attrs: { bordered: "", separator: "" } },
                    _vm._l(_vm.elenco_documenti, function (documento, index) {
                      return _c(
                        "q-item",
                        {
                          directives: [{ name: "ripple", rawName: "v-ripple" }],
                          key: index,
                          attrs: { clickable: "" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.ScaricaDocumentazioneProposta(
                                documento
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "q-item-section",
                            { attrs: { avatar: "" } },
                            [
                              _c("q-avatar", {
                                attrs: {
                                  color: "blue-grey",
                                  "text-color": "white",
                                  icon: "mdi-download",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("q-item-section", [
                            _c("strong", [
                              _vm._v(_vm._s(documento.descrizione)),
                            ]),
                          ]),
                          _c(
                            "q-item-section",
                            { attrs: { side: "" } },
                            [
                              _c("q-item-label", { attrs: { caption: "" } }, [
                                _c(
                                  "strong",
                                  { staticStyle: { "font-size": "1.4em" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.transcodificaAzioneDocumento(
                                          documento.azione
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c("br"),
            ]
          ),
        ]),
        _c("br"),
        _c("hr"),
        _c("div", { staticClass: "row justify-center" }, [
          _c(
            "div",
            { staticClass: "col-12 col-md-4", attrs: { align: "center" } },
            [
              _c("QQButton", {
                attrs: {
                  label: "INDIETRO",
                  color: "blue-grey",
                  icon: "undo",
                  size: "md",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.onIndietroClicked.apply(null, arguments)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-12 col-md-4", attrs: { align: "center" } },
            [
              _c("QQButton", {
                attrs: {
                  label: "ANNULLA EMISSIONE",
                  color: "blue-grey",
                  icon: "mdi-close-octagon",
                  size: "md",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.gotoStart()
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-12 col-md-4", attrs: { align: "center" } },
            [
              _c("QQButton", {
                attrs: {
                  label: "AVANTI",
                  color: "blue-grey",
                  icon: "mdi-arrow-right-bold",
                  size: "md",
                  disabled: _vm.isEmailDisabled,
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.onAvantiClicked.apply(null, arguments)
                  },
                },
              }),
            ],
            1
          ),
        ]),
        _c("br"),
        _c("br"),
        _c("br"),
        _c("br"),
        _c("br"),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [
      _c("p", { staticClass: "text-h5" }, [
        _vm._v("(1) - Dichiarazioni del Contraente"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [
      _c("p", { staticClass: "text-h5" }, [
        _vm._v("(1A) - Certificazione recapito OTP"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { align: "left" } }, [
      _vm._v(
        " Questa procedura serve per stabilire che il numero di cellulare appartiene al Contraente. Per fare questa verifica esegui i seguenti step: "
      ),
      _c("ol", [
        _c("li", [
          _vm._v('Clicca sul pulsante "Effettua la certificazione della SIM"'),
        ]),
        _c("li", [
          _vm._v(
            'Clicca sul pulsante "INVIA SMS" che invia un codice alfanumerico al cellulare del Contraente chiamato "Codice OTP"'
          ),
        ]),
        _c("li", [
          _vm._v("Vengono attivate alcune domande che devi Confermare"),
        ]),
        _c("li", [
          _vm._v(
            "Si attiva il campo dove devi inserire il codice OTP che ti comunica il Contraente"
          ),
        ]),
        _c("li", [
          _vm._v(
            'Clicca sul pulsante "VERIFICA" per accertare che il codice OTP che hai inserito sia lo stesso che ha ricevuto il Contraente'
          ),
        ]),
        _c("li", [
          _vm._v(
            "Se il codice è corretto, viene comunicato il successo, altrimenti viene restituito un errore e la procedura va ripetuta"
          ),
        ]),
        _c("li", [
          _vm._v(
            "Conferma l'ultima domanda dove l'Intermediario certifica che il cellulare appartiene al Contraente"
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [
      _c("p", { staticClass: "text-h5" }, [
        _vm._v("(2) - Invia documenti al Cliente per Email"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "Dopo aver scaricato le dichiarazioni del Cliente, inviagli una Email. Verifica che l'indirizzo sotto sia corretto prima di continuare. "
      ),
      _c("strong", [_vm._v("Se è errato, torna indietro e modificalo.")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [
      _c("p", { staticClass: "text-h5" }, [
        _vm._v("(3) - Altre Informazioni da leggere "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [
      _c("p", { staticClass: "text-h5" }, [
        _vm._v("(4) - Documentazione Proposte Selezionate"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }